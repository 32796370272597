import thumbnails from './layout-thumbnails';

export interface Preset {
  id: string;
  width: number;
  height: number;
  thumbnailWidth: number;
  thumbnailHeight: number;
  thumbnailSrc: string;
}

export enum WidgetState {
  Default = 'default',
  VerticalBottomButton = 'vertical-bottom-button',
  Horizontal = 'horizontal',
  HorizontalWideButton = 'horizontal-wide-button',
}

export enum PresetId {
  vertical = 'variants-l8wxqpaw',
  vertical_no_image = 'variants-l9gups7t',
  vertical_tt_d = 'variants-l60nfem1',
  vertical_fb = 'variants-l60lthp5',
  vertical_fb_no_image = 'variants-l9mlptiv',
  vertical_fb_tt_d = 'variants-l9mlmpkm',
  horizontal = 'variants-l5upxns2',
  horizontal_no_image = 'variants-l9mlr37s',
  horizontal_tt_d = 'variants-l6ai5twg',
  horizontal_fb = 'variants-l64qe8a4',
  horizontal_fb_no_image = 'variants-l9mlsuqi',
  horizontal_fb_tt_d = 'variants-l9mm2pv1',
}

export enum RootPresetId {
  Desktop = 'variants-l9mt9pib1',
  Mobile = 'variants-lbbv6kl4',
}

export const isValidPresetId = (id: string): id is PresetId => {
  return Object.values(PresetId).includes(id as PresetId);
};

export const PRESETS: Record<PresetId, Preset> = {
  [PresetId.vertical]: {
    id: PresetId.vertical,
    thumbnailWidth: 114,
    thumbnailHeight: 258,
    thumbnailSrc: thumbnails.vertical,
    width: 300,
    height: 200,
  },
  [PresetId.vertical_no_image]: {
    id: PresetId.vertical_no_image,
    thumbnailWidth: 114,
    thumbnailHeight: 222,
    thumbnailSrc: thumbnails.vertical_no_image,
    width: 300,
    height: 200,
  },
  [PresetId.vertical_tt_d]: {
    id: PresetId.vertical_tt_d,
    thumbnailWidth: 114,
    thumbnailHeight: 222,
    thumbnailSrc: thumbnails.vertical_tt_d,
    width: 300,
    height: 200,
  },
  [PresetId.vertical_fb]: {
    id: PresetId.vertical_fb,
    thumbnailWidth: 114,
    thumbnailHeight: 259,
    thumbnailSrc: thumbnails.vertical_fb,
    width: 300,
    height: 200,
  },
  [PresetId.vertical_fb_no_image]: {
    id: PresetId.vertical_fb_no_image,
    thumbnailWidth: 114,
    thumbnailHeight: 222,
    thumbnailSrc: thumbnails.vertical_fb_no_image,
    width: 304,
    height: 200,
  },
  [PresetId.vertical_fb_tt_d]: {
    id: PresetId.vertical_fb_tt_d,
    thumbnailWidth: 114,
    thumbnailHeight: 222,
    thumbnailSrc: thumbnails.vertical_fb_tt_d,
    width: 300,
    height: 200,
  },
  [PresetId.horizontal]: {
    id: PresetId.horizontal,
    thumbnailWidth: 240,
    thumbnailHeight: 126,
    thumbnailSrc: thumbnails.horizontal,
    width: 980,
    height: 410,
  },
  [PresetId.horizontal_tt_d]: {
    id: PresetId.horizontal_tt_d,
    thumbnailWidth: 240,
    thumbnailHeight: 126,
    thumbnailSrc: thumbnails.horizontal_tt_d,
    width: 980,
    height: 410,
  },
  [PresetId.horizontal_no_image]: {
    id: PresetId.horizontal_no_image,
    thumbnailWidth: 240,
    thumbnailHeight: 126,
    thumbnailSrc: thumbnails.horizontal_no_image,
    width: 560,
    height: 410,
  },

  [PresetId.horizontal_fb]: {
    id: PresetId.horizontal_fb,
    thumbnailWidth: 240,
    thumbnailHeight: 124,
    thumbnailSrc: thumbnails.horizontal_fb,
    width: 980,
    height: 410,
  },
  [PresetId.horizontal_fb_tt_d]: {
    id: PresetId.horizontal_fb_tt_d,
    thumbnailWidth: 240,
    thumbnailHeight: 126,
    thumbnailSrc: thumbnails.horizontal_fb_tt_d,
    width: 980,
    height: 410,
  },
  [PresetId.horizontal_fb_no_image]: {
    id: PresetId.horizontal_fb_no_image,
    thumbnailWidth: 240,
    thumbnailHeight: 126,
    thumbnailSrc: thumbnails.horizontal_fb_no_image,
    width: 560,
    height: 410,
  },
};

export const PRESETS_BY_STATE: Record<WidgetState, Preset[]> = {
  [WidgetState.Default]: [
    PRESETS[PresetId.vertical_tt_d],
    PRESETS[PresetId.vertical],
    PRESETS[PresetId.vertical_no_image],
  ],
  [WidgetState.VerticalBottomButton]: [
    PRESETS[PresetId.vertical_fb_tt_d],
    PRESETS[PresetId.vertical_fb],
    PRESETS[PresetId.vertical_fb_no_image],
  ],
  [WidgetState.Horizontal]: [
    PRESETS[PresetId.horizontal],
    PRESETS[PresetId.horizontal_tt_d],
    PRESETS[PresetId.horizontal_no_image],
  ],
  [WidgetState.HorizontalWideButton]: [
    PRESETS[PresetId.horizontal_fb],
    PRESETS[PresetId.horizontal_fb_tt_d],
    PRESETS[PresetId.horizontal_fb_no_image],
  ],
};

export const DEFAULT_PRESETS_BY_STATE: Record<WidgetState, Preset> = {
  [WidgetState.Default]: PRESETS[PresetId.vertical],
  [WidgetState.VerticalBottomButton]: PRESETS[PresetId.vertical_fb],
  [WidgetState.Horizontal]: PRESETS[PresetId.horizontal],
  [WidgetState.HorizontalWideButton]: PRESETS[PresetId.horizontal_fb],
};

export const MOBILE_PRESET_BY_DESKTOP_PRESET_ID: Record<PresetId, PresetId> = {
  [PresetId.vertical]: PresetId.vertical,
  [PresetId.vertical_no_image]: PresetId.vertical_no_image,
  [PresetId.vertical_tt_d]: PresetId.vertical_tt_d,
  [PresetId.vertical_fb]: PresetId.vertical_fb,
  [PresetId.vertical_fb_no_image]: PresetId.vertical_fb_no_image,
  [PresetId.vertical_fb_tt_d]: PresetId.vertical_fb_tt_d,

  [PresetId.horizontal]: PresetId.vertical,
  [PresetId.horizontal_no_image]: PresetId.vertical_no_image,
  [PresetId.horizontal_tt_d]: PresetId.vertical_tt_d,
  [PresetId.horizontal_fb_tt_d]: PresetId.vertical_fb_tt_d,
  [PresetId.horizontal_fb]: PresetId.vertical_fb,
  [PresetId.horizontal_fb_no_image]: PresetId.vertical_fb_no_image,
};
